<template lang="pug">
  .user-modal
    b-modal(id="role-modal", :title="title", size="md", ok-variant="outline-primary", cancel-variant="link", ref="wlistref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()
         
          .col-12
            b-form-group(id="name-group"  
                        label="List Name"
                        size="sm"
                       
                        label-for="name"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                    b-form-input(id="name"
                                type="text"
                                 @input="check_name(form.name)"
                                :state="!submitStatus ? null : !$v.form.name.$invalid"
                                size="sm"
                                placeholder="e.g. My Startup Watchlist", v-model="form.name")
       
            div(v-if="name_chosen").small.alert.alert-danger {{name_chosen}}

      template(slot="modal-footer")
            b-btn(variant="outline-secondary" @click="$refs.wlistref.hide(false)") Close
            b-btn(variant="outline-primary"  @click="save()", :disabled="name_chosen")#user-modal-save Save List



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        name_chosen: false,
        uid: null,
        wlists: null,
        title: "Save list",
        form: {"name": "", "items": [], "active": true, "send_confirmation": true},
        wlist: {},
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Role"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

  methods: {

    open(wlist, item_type) {
      
      this.title = "Save list";
      this.name_chosen = "";
       this.wlists = null;
       Vue.http.get(`${Config.config.base_host}/watchlists`).then(res => {
        this.wlists = res.body;
        this.$store.dispatch("stop_loading");
      });

      if(wlist) {
        this.form = JSON.parse(JSON.stringify(wlist));
        this.wlist = wlist;
        this.ok_btn_text = "Save";
        this.title = "Save " + wlist.name + ' ';
        
      } else {
        
        this.form = {"name": "", "permissions": [], "active": true, "send_confirmation": true};
        this.wlist =  {"name": "", "permissions": [], "active": true, "send_confirmation": true};
      
      }

      this.form.item_type = item_type;
      this.wlist.item_type  = item_type;
      this.$refs['wlistref'].show()
    },


    check_name(name) {
      this.name_chosen = false;
      if(!this.wlists) return;
      if(this.wlists.map(x => x.name).includes(name)) {
        this.name_chosen = "You've already created a list with this name, please choose a new one."
      }
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        if(this.form._id) {
          Vue.http.put(`${Config.config.base_host}/watchlists/${this.form._id}`, this.form).then(() => {
            this.$bvToast.toast("The items have been added to the list.", {
              title: 'Added to list',
              variant: 'primary',
              autoHideDelay: 1000,
            });

            this.$refs['wlistref'].hide();
            this.$emit("saved");
          }, (res) => {
            this.$bvModal.msgBoxOk("Error: " +  res.body.detail);
          });
        } else {
          Vue.http.post(`${Config.config.base_host}/watchlists`, this.form).then(() => {
            this.$bvToast.toast("The items have been added to the list.", {
              title: 'Added to list',
              variant: 'primary',
              autoHideDelay: 1000,
            });

            this.$refs['wlistref'].hide()
            this.$emit("saved");
          }, (res) => {
            this.$bvModal.msgBoxOk("Error: " +  res.body.detail);
          });
        }
       
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
