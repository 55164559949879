<template lang="pug">

.main(:key="'dd'+topic")
   
    template(v-if="internal_value && internal_value.indexOf(topic) > -1")
      div(@click="ran($event, topic)")
        b-checkbox#on(:checked="true", :key="'ran'+topic") {{label}}  

    template(v-else-if="internal_value && internal_value.indexOf('-'+topic) > -1")
        .div()
            b-checkbox(@input="ntr(topic)", :checked="true", :indeterminate="true", :key="'ntr'+topic")
                del() {{label}}
    
    template(v-else)
        b-checkbox(:checked="false", @input="rna(topic)", :key="'rna'+topic") {{label}} 

</template>

<script>
// @ is an alias to /src

export default {
  name: 'ThreeStateCheckbox',
  props: {
    topic: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: function () {
    return {
        uid: null,
        internal_value: null
    }
  },

  mounted: function() {
     this.internal_value = this.value;
     this.uid = this._uid;
  },

  watch: {

        value: function(newValue) {
            this.internal_value = newValue;
        },
       
        internal_value: function(val) {
          
          this.$emit('input', val);
        }
    },

     
  
  methods: {

      ran($event, topic) {
        
        this.internal_value = this.internal_value.filter(x => x !== topic);
        this.internal_value.push("-"+topic);
        this.$forceUpdate();
        $event.stopPropagation();
      },

        rna(topic) {
            
            this.internal_value.push(topic);
            this.$forceUpdate();
        },

        ntr(topic) {
            
            this.internal_value = this.internal_value.filter(x => x !== "-"+topic);
            this.$forceUpdate();
        }
    
  
  },

  components: {
   

  }
}

</script>
<style lang="scss" scoped>

.excluded {
    .custom-control-input:checked ~ .custom-control-label::before {
     background-color:red !important;  // just for sake of example.
     background: red;
    }
    .custom-control-label:before {
        background-color: red;
    }
}
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
