<template lang="pug">
  .company-modal
    b-modal(id="modal", :title="title", size="xl", ok-variant="outline-primary", cancel-variant="link", ref="modal-company-ref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()
        
          .col-6
            b-form-group(id="exampleInputGroup1"  
                        label="Website"
                        size="sm"
                        label-for="exampleInput1"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.homepage_url.$error }")

                    b-form-input(id="homepage_url"
                                type="text"
                                :state="!submitStatus ? null : !$v.form.homepage_url.$invalid"
                                size="sm"
                                
                                placeholder="domain.com", @change="check_duplicates(form)", v-model="form.homepage_url")
          
          .col-6
            b-form-group(id="exampleInputGroup1"
                        label="Company name"
                        label-for="exampleInput1",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }"
                        description="")
                    b-form-input(id="name"
                                type="text"
                                size="sm"
                                @change="check_duplicates(form)"
                                :state="!submitStatus ? null : !$v.form.name.$invalid"
                                placeholder="",  v-model="form.name")

        .form-row(v-if="duplicates && duplicates.length").mb-3
          .col-12
              b-card(v-if="duplicate_source=='hyve_db'")
                .small.text-muted() Did you mean this company? Click to take over.
                a.small(v-for="it in duplicates.slice(0,1)", @click="form=it", href="javscript:void(0)") {{it.name}}, {{it.city}}, {{it.web_entity}}
              b-card(v-if="duplicate_source=='instance_db'")
                .small.text-muted() Caution: We've found this company already in your ecosystem:  
               
                div.small(v-for="it in duplicates.slice(0,1)", href="javscript:void(0)").mt-2
                  .mt-3.mb-1 {{it.name}}, {{it.city}}, {{it.web_entity}}
                  b-btn(:href="'/ecosystem/orgs/'+it._id", size="sm", variant="outline-success") Open profile

                  .text-muted().mt-3 Do you would like to create a new company based on this data? Click to take over.
                  b-btn(@click="form=it", size="sm" variant="outline-secondary") Take over

        .form-row

          .col-12
            b-form-group(id="short_description-group"
                        label="Short description"
                        label-for="short_description",
                        )
              b-textarea(v-model="form.short_description", rows="2", id="short_description" size="sm", placeholder="Company description")
        
        .form-row.mt-3

          b-tabs(style="width: 100%" card, size="sm")
       
            b-tab(title="Info", id="tab-info")
              .form-row
                  .col-4
                      b-form-group(id="location_city-group"
                                  label="City"
                                  label-for="location_city"
                                  description="")
                              
                                b-form-input(id="location_city"
                                          type="text"
                                          size="sm"
                                          placeholder="", v-model="form.city")

                  .col-4
                      b-form-group(id="location_region-grup"
                                  label="Region / State"
                                  label-for="location_region"
                                  description="")
                           
                                b-form-input(id="location_region"
                                          type="text"
                                          size="sm"
                                          placeholder="",  v-model="form.region")
                  .col-4
                      b-form-group(id="location_region-group"
                                  label="Country Code"
                                  label-for="location_region"
                                  description="")
                                
                                b-form-input(id="location_region"
                                          type="text"
                                          size="sm"
                                          placeholder="",  v-model="form.country_code")

                                //CountrySelect(v-model="form.location_country_code", id="location_country_code", size="sm")
                 
              
              .form-row
                .col-4
                  b-form-group(id="founding_date-group"
                              label="Founding date"
                              label-for="founding_date"
                              description="")
                        b-input-group(size="sm")
                            
                            b-form-input(id="founding_date"
                                      type="text"
                                      size="sm", v-model="form.founding_date")

                .col-4
                  b-form-group(id="employee_count-group"
                              label="Employee count"
                              label-for="employee_count"
                              description="")
                        b-input-group(size="sm")
            
                            b-form-input(id="employee_count"
                                      type="text"
                                      size="sm", v-model="form.employee_count")

            
              b-form-group(id="description-group"
                        label="Long description"
                        label-for="description"
                        description="")
                  b-input-group(size="sm")
                      
                      b-textarea(v-model="form.description", id="description", rows="2", size="sm", placeholder="Company description")
              
              b-form-group(id="banner-group"
                      v-if="instance_config.app_config.base.version == 'dev354'"
                      label="Banner Image URL"
                      label-for="banner"
                      description="* I/we own the redistribution rigths and would like to publish the image on this platform")
                b-input-group(size="sm")
                    
                    b-input(v-model="form.custom_data.youtube_url", id="banner",  size="sm", placeholder="Banner Image URL")
                    
              b-form-group(id="youtube-group"
                        label="Youtube URL"
                        label-for="youtube"
                        description="")
                  b-input-group(size="sm")
                      
                      b-input(v-model="form.custom_data.youtube_url", id="youtube",  size="sm", placeholder="URL to Youtube Video")
           
            //b-tab(title="Investments", size="sm", id="tab-investments")

              b-form-group(id="funding_total_origin_currencyGroup"
                          label="Total Funding"
                          label-for="funding_total_origin_currency-1"
                          description="")
                    b-input-group(size="sm")
                    
                      select(v-model="form.total_funding_currency_code", id="funding_total_origin_currency-1")
                        option(value="USD") $
                        option(value="EUR") €
                      
                      b-form-input(id="funding_total_origin-1"
                                type="number"
                                size="sm"
                                :placeholder="'Amount in ' + form.total_funding_currency_code", v-model="form.total_funding", style="max-width: 200px")
                    
                        
              b-form-group(id="exampleInputGroup1"
                          label="Investors"
                          label-for="exampleInput1"
                          description="").mt-2
                    
                      v-select(id="investors"
                              :options="[]",
                              :multiple="true"
                              :taggable="true"
                              :close-on-select="false"
                              :no-drop="true"
                              :select-on-tab="true"
                              placeholder="Investors (enter name and press enter)", 
                              v-model="form.investors")


            b-tab(title="Categories", size="sm", id="tab-categories")
              .mt-2(v-if="instance_config.app_config.base.version == 'dev354'") 
               
                  .form-row
     
                    template(v-for="field in project_config")
                      b-form-group(:id="'id' + field.topic_type", v-if="field.topic_type != 'sub_vertical' || (form.custom_data['vertical'] && form.custom_data['vertical'].indexOf('db8c995d6b52770ef61bc39821d3a3d5') > -1)", id="org_tags-group", :label="field.name", label-for="org_tags", label-size="sm", ).col-6
                        TaxonomySelect(id="stage"
                                    
                                    :topic_type="field.topic_type",
                                    :multiple="true"
                                    :select-on-tab="true"
                                    v-model="form.custom_data[field.topic_type]").small
                
                    
              b-form-group(id="tags-group"
                        label="Tags"
                        label-for="tags"
                        description="")
                 
                      v-select(id="tag"
                                      :options="tags_options",
                                      :multiple="true"
                                      :taggable="true"
                                      
                                      placeholder="Enter tags and press enter"
                                      :select-on-tab="true"
                                      v-model="form.tags").small
                        template(#no-options="{ search, searching, loading }")
                          | Type a name and press enter to add new tag
    

              b-form-group(id="categories-group"
                      label="Organization Types"
                      label-for="categories"
                      description="")
                
                    v-select(id="tag"
                                    :options="$store.state.instance_config.organization_types",
                                    :multiple="true"
                                    :taggable="false"
                                    
                                    
                                    placeholder="Enter type and press enter"
                                    :select-on-tab="true"
                                    v-model="form.categories").small
              

              b-form-group(id="focus-group"
                        label="Focus Topic"
                        label-for="tags"
                        description="Exclude news that are not within this topic.")
                 
                    TopicSelect(v-model="form.focus_topic_ids", :create_option="false", :multiple="true", :edit="true", topic_type="topic", :need_query="true", :show_follow_only="false").small
                
            b-tab(title="Social / Contacts", size="sm", id="tab-profiles")
            
              .row.mt-3
                div().col-6

                        b-form-group(id="emailgroup"
                              label="E-Mail"
                              size="sm"
                              label-for="email"
                              description="")
                          
                          b-form-input(id="email"
                                      type="text"
                                      size="sm",  v-model="form.email")
                                      
                     
                        b-form-group(id="exampleInputGroup1"
                              label="LinkedIn"
                              size="sm"
                              label-for="linkedin_url"
                              description="")
                          
                          b-form-input(id="linkedin_url"
                                      type="text"
                                      size="sm",  v-model="form.linkedin_url")
                                      
                        b-form-group(id="exampleInputGroup1"
                                label="Crunchbase"
                                label-for="crunchbase_url"
                                description="")

                            b-form-input(id="crunchbase_url"
                                        type="text"
                                        size="sm", v-model="form.crunchbase_url")
                          
                        b-form-group(id="exampleInputGroup1"
                                label="Pitchbook"
                                size="sm"
                                label-for="pitchbook_url"
                                description="")
                            b-form-input(id="pitchbook_url"
                                        type="text"
                                        size="sm", v-model="form.pitchbook_url")
                            
                div().col-6


                        b-form-group(id="phonegroup"
                            label="Phone"
                            label-for="phone_number"
                            description="")

                          b-form-input(id="phone_number"
                                    type="text"
                                    size="sm", v-model="form.phone_number")


                        b-form-group(id="exampleInputGroup1"
                                label="Twitter"
                                label-for="twitter_url"
                                description="")

                            b-form-input(id="twitter_url"
                                        type="text"
                                        size="sm", v-model="form.twitter_url")


                        b-form-group(id="exampleInputGroup1"
                              label="Facebook"
                              label-for="facebook_url"
                              description="")

                          b-form-input(id="facebook_url"
                                      type="text"
                                      size="sm", v-model="form.facebook_url")
                       
                        
                        b-form-group(id="exampleInputGroup1"
                                label="AngelList"
                                size="sm"
                                label-for="angel_url"
                                description="")
                            b-form-input(id="angel_url"
                                        type="text"
                                        size="sm", v-model="form.angel_url")

            b-tab(title="Financials", size="sm", id="tab-financiles")

              b-form-group(id="exampleInputGroup1"
                        label=""
                        size="sm"
                        label-for="use_internal_funding_data"
                        description="")

                b-check(id="use_internal_funding_data"
                            type="text"
                            size="sm", v-model="form.use_internal_funding_data") Use own funding information


              table.table(v-if="form.use_internal_funding_data")
                tr().d-flex
                  th().small.col-2 Announced date
                  th().small.col-3 Round
                  th().small.col-2 Amount ($)
                  th().small.col-4 Investors
                  th().small.col-1 
                
                tr(v-for="(fr,i) in $_.sortBy(form.relations.funding_rounds, ['announced_on'])", :key="fr._id").d-flex
                  td().small.col-2 {{fr.announced_on | moment("YYYY-MM-DD")}}
                  td().small.col-3 {{fr.investment_type}}
                  td().small.col-2 {{fr.raised_amount_usd}}
                  td().small.col-4
                    CompanyIdSelect(size="sm", v-model="fr.investors_organization_cb_uuids", label="Search Investor", :editable="false")
                  th().small.col-1
                    b-btn(size="sm", variant="link", @click="remove_fr(fr)").p-0 Remove
                
                tr().d-flex
                  td(colspan="5").col-12
                    .mt-4 Add new round

                tr().d-flex
                  td().col-2
                    b-form-datepicker(v-model="new_round.announced_on", :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }", placeholder="Date").mr-3
                  td().col-3
                    b-select(:options="last_funding_type_options", v-model="new_round.investment_type", size="sm")

                  td().col-2
                    b-input(type="number", v-model="new_round.raised_amount_usd", size="sm", currency="USD").form-control.small
                  td().col-4
                    CompanyIdSelect(size="sm", v-model="new_round.investors_organization_cb_uuids", label="Search Investor", :editable="true")
                    
                    .float-right.mt-1
                     
                  td().small.col-1
                     b-btn(:disabled="!new_round.investment_type || !new_round.announced_on", variant="outline-primary", size="sm", @click="add_funding_round(form.relations.funding_rounds, new_round)").w-100 Add
                  
            //b-tab(title="GDPR", size="sm", id="tab-gdpr")
              .row.mt-3
                  b-table(:items="form.gdpr_reviews", size="sm").small
                
              label Add data review
              b-form(@submit.prevent="form.gdpr_reviews.push(Object.assign({}, new_gdpr_review))")
                .form-row
                  .col-4
                    b-input(v-model="new_gdpr_review.name", placeholder="Full name", size="sm", required)
                  .col-4
                    b-form-datepicker(v-model="new_gdpr_review.checked_at", required)
                  .col-4
                    b-btn(type="submit", size="sm", :disabled="!new_gdpr_review.checked_at || !new_gdpr_review.name") Add review
              
              .mt-3
              label Notification period in months
              b-input(type="number", v-model="form.gdpr_notify_every_months", size="sm", required, style="width: 200px") Month


      template(slot="modal-footer")
            
            b-btn(variant="outline-secondary" @click="$refs['modal-company-ref'].hide(false)") Close
            b-btn(variant="outline-primary"  @click="save()")#add-company-save {{ok_btn_text}}

</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import CompanyIdSelect from '@/components/CompanyIdSelect'

import Config from '@/config'
import Vue from 'vue'
import TaxonomySelect from '@/components/TaxonomySelect'
import TopicSelect from '@/components/TopicSelect'
import { mapFields } from 'vuex-map-fields';

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        },
        homepage_url: {
            required,
            minLength: minLength(1)
        }
    }
  },

  data: function () {
    return {
        new_round: {"raised_amount_usd": null, "investment_type": null, "announced_on": null, "investors_organization_cb_uuids": [], "_id": null},
        new_round_protoype: {"raised_amount_usd": null, "investment_type": null, "announced_on": null, "investors_organization_cb_uuids": [], "_id": null},
        new_gdpr_review: {"checked_at": null, "name": ""},
        uid: null,
        title: "Add a new company",
        form: {"custom_data": {industry: [],enterprise_function: [], vertical: [], sub_vertical: []}, "domain": "", "name": "", total_funding_currency_code: "USD", selectedLists: [], contacts: [],  total_funding: 0, },
        project_config: [{"name": "Industry", "topic_type": "industry"},{"name": "Enterprise Function", "topic_type": "enterprise_function"},{"name": "Vertical",  "topic_type": "vertical"}, {"name": "Sub-Vertical",  "topic_type": "sub_vertical"}],
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Company",
        duplicates: null
        
    }
  },

  computed: {

    ...mapFields([
      
        'filter_options.crunchbase_category_list_options',
        'filter_options.tags_options',
        'filter_options.crunchbase_category_groups_list_options',
        'filter_options.country_code_options',
        'filter_options.city_options',
        'filter_options.region_options',
        'filter_options.categories_options',
        'filter_options.last_funding_type_options',
        'instance_config'
    ]),
  },

  mounted: function() {
     this.uid = this._uid;
     this.new_gdpr_review.name = this.$store.state.user.full_name;
     this.$store.dispatch("get_filter_options");
  },  

  methods: {

    remove_fr(fr) {
      let index = this.form.relations.funding_rounds.map(x=>x._id).indexOf(fr._id)
      console.log(index);
      this.form.relations.funding_rounds.splice(index,1);
    },
    
    getUUID() {
      return  Math.random().toString();
    },

    add_funding_round(frs, new_round) {
      frs.push( Object.assign({"_id": this.getUUID()}, new_round));
      this.new_round = Object.assign({}, this.new_round_protoype);
    },

    check_duplicates(org) {
      this.duplicates = null;
      let org_to_check = Object.assign({}, org);
      org_to_check.web_entity = org_to_check.homepage_url;

      Vue.http.post(`${Config.config.base_host}/import/duplicate_check`, org_to_check).then(res => {
          this.duplicates = res.body.orgs;
          this.duplicate_source = res.body.source;
      });
    },

    open(org) {
      this.new_round = Object.assign({}, this.new_round_protoype);
      if(org) {
        
        if(!org.relations.funding_rounds) {
          org.relations.funding_rounds = [];
        }

        if(!org.custom_data) {
          org.custom_data = {"custom_data": {industry: [],enterprise_function: [], vertical: [], sub_vertical: []}}
        }
        if(!org.custom_data.industry) {
          org.custom_data.industry = [];
        }
         if(!org.custom_data.enterprise_function) {
          org.custom_data.enterprise_function = [];
        }
         if(!org.custom_data.vertical) {
          org.custom_data.vertical = [];
        }
         if(!org.custom_data.sub_vertical) {
          org.custom_data.sub_vertical = [];
        }

        this.form = JSON.parse(JSON.stringify(org));
        if(!org.homepage_url && org.web_entity) this.form.homepage_url = org.web_entity;
        if(!org.description && org.registration_subject) this.form.description = org.registration_subject;
        this.ok_btn_text = "Save";
        this.title = "Edit " + org.name;
        

      } else {
        this.form = {"custom_data": {industry: [],enterprise_function: [], vertical: [], sub_vertical: []},"domain": "", "name": "", total_funding_currency_code: "USD", selectedLists: [], contacts: [],  total_funding: 0};
        this.duplicates = null;
      }


      this.$refs['modal-company-ref'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        Vue.http.post(`${Config.config.base_host}/organizations`, this.form).then(res => {
          location.href="/ecosystem/orgs/" + res.body._id;
        });
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    CompanyIdSelect,
    TaxonomySelect,
    TopicSelect
  }
}

</script>
<style lang="scss" scoped>

@import '~@/_vars.scss';

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: $gray-900 !important;
}
</style>
