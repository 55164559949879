<template lang="pug">
  .filter-group

        .row(v-if="!editable")
                     
          .col-12
           
            div(v-for="item in internal_value", :to="'/ecosystem/orgs/'+item._id")
             
              .media
                div(class="feed-image-small" :style="'background: url(https://images.hytechnologies.co/unsafe/30x30/logo.clearbit.com/' + item.web_entity + ') center 0% / cover no-repeat;'").mr-1
                .media-body 
                  div {{item.name}} 

        .row(v-if="editable")
               
          .col-12
            
            .badge.badge-secondary(v-for="org in internal_value").mr-1.mb-2 {{org.name}} <i class="fas fa-times text-gray" @click="remove(org)"></i>

            div()
              b-dropdown(id="dropdown-company-select" :text="label", size="sm", left, variant="outline-secondary", ref="dropdown")
                b-dropdown-form()
                  b-form-group
                    b-form(@submit.prevent="search(query)", autocomplete="off")
                      b-btn-group()
                        b-form-input(@input="search(query)", type="search", v-model="query",size="sm", style="min-width: 200px", placeholder="Enter search keyword")
                        b-btn(@click="search(query)", size="sm", variant="outline-primary") Search
                  b-form-group
                    .results
                      i(v-if="results != null && results.length == 0") No results found for "{{query}}"
                      i(v-if="results == null") Enter keyword and press "{{label}}"
                      b-list-group(size="sm", style="max-height: 200px; overflow-y: auto")
                        b-list-group-item(button  v-for="(item, index) in results", @click="add(item)").d-flex.justify-content-between.align-items-center.m-0.pl-1.pr-1.pt-1.pb-1
                          .media
                            div(class="feed-image-small" :style="'background: url(https://images.hytechnologies.co/unsafe/30x30/logo.clearbit.com/' + item.web_entity + ') center 0% / cover no-repeat;'").mr-3
                            .media-body 
                              div {{item.name}} 
                                .d-inline-block.gray-info.ml-1 {{item.web_entity}}
                          .d-inline-block.gray-info.ml-5.small {{item.city}} 
                
        
    

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
//import debounce from 'lodash/debounce';
export default {
  name: 'CompanyIdSelect',
  props:  {
    "value":  {
      default: null
    }, 

    "placeholder":  {
      default: ""
    },
    
    "label": {
      default: "Search"
    },
    "editable": {
      type: Boolean,
      default: false
    },
    "multiple": {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
        uid: null,
        options: [],
        internal_value: null,
        results: null,
        query: null,
        selected: null,
    }
  },

  watch: {
    'value': function() {
      this.internal_value = [];
      if(this.value && Array.isArray(this.value)) {

       this.value.forEach(orgid=>{
          Vue.http.get(`${Config.config.base_host}/organizations/${orgid}`).then(res => {
            this.internal_value.push(res.body);
          });
       });
     } else if(this.value) {
        Vue.http.get(`${Config.config.base_host}/organizations/${this.value}`).then(res => {
            this.internal_value.push(res.body);
        });
     }

    }

  },

  mounted: function() {
     this.uid = this._uid;
     this.internal_value = [];
      if(this.value && Array.isArray(this.value)) {

       this.value.forEach(orgid=>{
          Vue.http.get(`${Config.config.base_host}/organizations/${orgid}`).then(res => {
            this.internal_value.push(res.body);
          });
       });
     } else if(this.value) {
        Vue.http.get(`${Config.config.base_host}/organizations/${this.value}`).then(res => {
            this.internal_value.push(res.body);
        });
     }
     if(!this.internal_value) this.internal_value = [];
  },

     
  
  methods: {
    get_image() {

      return (this.internal_value && this.internal_value.urls) ? this.internal_value.urls.raw + '?crop=true&w=320&h=180&fit=crop' : ''
    },

    add(obj) {
      this.internal_value.push(obj);
      this.$emit("input", this.internal_value.map(x => x._id));
      this.$refs.dropdown.hide(true);
    },

     
    remove(obj) {
      this.$_.remove(this.internal_value, {_id: obj._id});
      
      this.$emit("input", this.internal_value.map(x => x._id));
      this.$forceUpdate();
      this.$refs.dropdown.hide(true);
    },

    search(query) {
      
     
      Vue.http.post(`${Config.config.base_host}/organizations/search`, {"filter": {"name_str": query, "sources": ["hy", "ecosystem"]}}).then(res => {
        this.results = res.body.rows.slice(0,20);
      });

    }
  },

  components: {
    
  }
}

</script>
<style lang="scss" scoped>

.small-image {
  width: 200px;
  height: 150px;
}

.selected-image {
  width: 200px;
  height: 100%;
  min-height: 100px;
}

.credit {
  position: absolute;
  background-color: hsla(0, 0, 0, 0.5);
  bottom: 0;
  padding: 5px;
  left:0px;
  right:0;
}

.credit-selected {
  position: absolute;
  background-color: hsla(0, 0, 0, 0.5);
  bottom: 0;
  padding: 5px;
  left:0px;
  right:0;
   width: 200px;
}
.results {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
