<template lang="pug">
  .filter-group
   
      
        .row
          
          .col-8(v-if="internal_value && internal_value.user")
            
            div(style="position: relative;").h-mouseover-parent
              div(class="selected-image" :style="'background: url(' + get_image() + ') center 0% / cover no-repeat;'")
              .credit-selected.small.h-mouseover-show Photo by <a :href="internal_value.user.links.html">{{internal_value.user.name}}</a> on <a :href="internal_value.user.links.html">Unsplash</a>
                
          .col-4
            div(v-bind:class="{'float-right': internal_value && internal_value.user}")
              b-dropdown(id="dropdown-image" text="Select Image", size="sm", right, variant="outline-secondary", ref="dropdown")
                b-dropdown-form()
                  b-form-group
                    b-form(@submit.prevent="search(query)", autocomplete="off")
                      b-btn-group()
                        b-form-input(type="search", v-model="query",size="sm", style="min-width: 400px", placeholder="Enter search keyword")
                        b-btn(@click="search(query)", size="sm", variant="outline-primary") Search
                  b-form-group
                    .results
                      i(v-if="results != null && results.length == 0") No images found for "{{query}}"
                      i(v-if="results == null") Enter keyword and press "Search" to find images
                      .row
                        .col-4(v-for="image in results", @click="select(image)", style="cursor: pointer; padding: 2px").h-mouseover-parent 
                          .credit.small.h-mouseover-show(v-if="image.user") Photo by <a :href="image.user.links.html">{{image.user.name}}</a> on <a :href="image.user.links.html">Unsplash</a>
                          div(class="small-image" :style="'background: url(' + image.urls.thumb + ') center 0% / cover no-repeat;'")

        
    

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
//import debounce from 'lodash/debounce';
export default {
  name: 'ImageSelect',
  props: ["value"],
  data: function () {
    return {
        uid: null,
        options: [],
        internal_value: null,
        results: null,
        query: null,
        selected: null
    }
  },

  mounted: function() {
     this.uid = this._uid;
     this.internal_value = this.value;
  },

     
  
  methods: {
    get_image() {

      return (this.internal_value && this.internal_value.urls) ? this.internal_value.urls.raw + '?crop=true&w=320&h=180&fit=crop' : ''
    },

    select(image) {
      this.internal_value = image;
      this.$emit("input", this.internal_value);
      this.$refs.dropdown.hide(true);
    },

    search(query) {
      if(!query || query.length < 3) return;
      Vue.http.get(`${Config.config.base_host}/images/search?query=${escape(query)}`).then(res => {
        this.results = res.body.results;
       
      });

    }
  },

  components: {
    
  }
}

</script>
<style lang="scss" scoped>

.small-image {
  width: 150px;
  height: 100px;
}

.selected-image {
  width: 150px;
  height: 100%;
  min-height: 80px;
}

.credit {
  position: absolute;
  background-color: hsla(0, 0, 0, 0.5);
  bottom: 0;
  padding: 5px;
  left:0px;
  right:0;
}

.credit-selected {
  position: absolute;
  background-color: hsla(0, 0, 0, 0.5);
  bottom: 0;
  padding: 5px;
  left:0px;
  right:0;
   width: 200px;
}
.results {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
