<template lang="pug">
  .project-modal
    b-modal(id="project-modal", :title="title", size="xl", ok-variant="outline-primary", cancel-variant="link", ref="projectref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()
          .col-12
            b-card 
              .form-row()
                .col-6
                  b-form-group(id="name-group"  
                              label="Name*"
                              size="sm"
                              label-for="name"
                              description="",
                              v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                          b-form-input(id="name"
                                      type="text"
                                      :state="!submitStatus ? null : !$v.form.name.$invalid"
                                      size="sm"
                                      placeholder="", v-model="form.name")
                
                .col-6
                  b-form-group(id="name-group"  
                            label="Project image"
                            size="sm"
                            label-for="image"
                            description="",
                           )

                    ImageSelect(v-model="form.image_url_meta", id="image")
                  

              .form-row()
                .col-6
                  b-form-group(id="description-short-group"
                              label="Short description*"
                              label-for="description-short",
                              v-bind:class="{ 'form-group--error': $v.form.short_description.$error }"
                              description="")
                          b-textarea(id="description-short"
                                      type="text"
                                      size="sm"
                                      :rows="3"
                                      :state="!submitStatus ? null : !$v.form.short_description.$invalid"
                                      placeholder="Shortly summarize the project", @change="", v-model="form.short_description")

                .col-6
                  b-form-group(id="description-group"
                              label="Description*"
                              label-for="description",
                              v-bind:class="{ 'form-group--error': $v.form.description.$error }"
                              description="")
                          b-textarea(id="description"
                                      type="text"
                                      size="sm"
                                      :rows="3"
                                      :state="!submitStatus ? null : !$v.form.description.$invalid"
                                      placeholder="Describe the project's purpose in detail", @change="", v-model="form.description")
                
               
                      
                //.col-6
                  b-form-group(id="description-group"
                              label="Technology"
                              label-for="exampleInput1",
                            
                              description="")
                          b-textarea(id="description"
                                      type="text"
                                      size="sm"
                                      placeholder="Give a brief overview of potential technologies that could be relevant for this project", @change="", v-model="form.additional_description")

          .col-12(v-if="instance_config.app_config.base.version == 'dev354'")
            b-card.mt-2 
              b Select domain
                .form-row
                 

                  template(v-for="field in project_config")
                    b-form-group(v-if="field.topic_type != 'sub_vertical' || (form.custom_data['vertical'] && form.custom_data['vertical'].indexOf('db8c995d6b52770ef61bc39821d3a3d5') > -1)", id="org_tags-group", :label="field.name", label-for="org_tags", label-size="sm", ).col-6
                      TaxonomySelect(id="stage"
                                  
                                  :topic_type="field.topic_type",
                                  :multiple="true"
                                  :select-on-tab="true"
                                  v-model="form.custom_data[field.topic_type]").small
                   
                .col-12
                  div(v-if="min_domain_error").alert.alert-danger Please choose at least one industry or one enterprise function
          
          .col-12(v-if="instance_config.app_config.base.version == 'standard'")
            b-form-group(id="stages-group"
                          label="Current project Status"
                          label-for="exampleInput1"
                          description="").mt-2
                      
                      b-select(id="status"
                              :options="['Setup', 'Ongoing', 'Finished']",
                              v-model="form.status", size="sm")

     

        b-card.mt-2
          b Team
          .form-row
              //.col-12
                  b-form-group(id="owner_id-group"
                              label="Project Owner"
                              label-for="exampleInput1"
                              description="")
                          
                            UserMultiSelect(id="owner_id"
                                      :multiple="false"
                                      :close-on-select="true",
                                      placeholder="", v-model="form.owner_id")
              //.col-12
                  b-form-group(id="owner_id-group"
                              label="Project Owner"
                              label-for="exampleInput1"
                              description="")
                          
                            User(:user="$store.state.user", :size="25", :popover="true", :showName="true")

              .col-12
                  b-form-group(id="members-group"
                              label="Project Member"
                              label-for="exampleInput1"
                              description="")
                          
                            UserMultiSelect(id="members"
                                      :close-on-select="true",
                                      placeholder="", v-model="form.member_ids")
    
              
              .col-12
                  b-form-group(id="owner_check_group"
                              label="Visibility and Access"
                              label-for="owner_check"
                              description="")
                          
                            b-check(v-model="form.visible_members_only", id="owner_check", v-if="auth.has_access('private_project', null, 'add')") Private project: Only project members can see and work in the project
                            div(v-if="!auth.has_access('private_project', null, 'add')") 
                              | You don't have the rights to create private projects
                              b-btn(size="sm", variant="outline-secondary").ml-3 Request access
       

        
      template(slot="modal-footer")
            
            b-btn(variant="outline-secondary" @click="$refs.projectref.hide(false)") Close
            b-btn(variant="outline-primary"  @click="save()")#project-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import User from '@/components/User'
import ImageSelect from '@/components/ImageSelect'
import Config from '@/config'
import Vue from 'vue'
import auth from '@/router/auth.js'
import TaxonomySelect from '@/components/TaxonomySelect'

import { mapFields } from 'vuex-map-fields';


export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        },
        short_description: {
            required,
            minLength: minLength(1)
        },
        description: {
            required,
            minLength: minLength(1)
        }
     
    }
  },


  computed: {

        ...mapFields([
            'instance_config'     
        ]),
  },


  data: function () {
    return {  
        auth: auth,
        uid: null,
        title: "Add a new project",
        form: {"custom_data": {industry: [],enterprise_function: [], vertical: [], sub_vertical: []}, "description": "", "name": "", member_ids: [], owner_id: null, stages: ["Watch", "Review", "Closed", "Rejected"], status: 'Ongoing'},
        custom_data: {industry: [],enterprise_function: [], vertical: [], sub_vertical: []},
        project_config: [{"name": "Industry", "topic_type": "industry"},{"name": "Enterprise Function", "topic_type": "enterprise_function"},{"name": "Vertical",  "topic_type": "vertical"}, {"name": "Sub-Vertical",  "topic_type": "sub_vertical"}],
        options: [],
        min_domain_error: false,
        submitStatus: null,
        ok_btn_text: "Add Project",
        call_origin: null
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

  methods: {

    open(project, call_origin = "projects") {
      this.call_origin = call_origin;
      this.min_domain_error = false;

      if(project) {
        this.form = JSON.parse(JSON.stringify(project));
        this.ok_btn_text = "Save";
        this.title = "Edit " + project.name;
      } else  {
        this.form = {"custom_data": {industry: [], enterprise_function: [], vertical: [], sub_vertical: []}, "description": "", "name": "", member_ids: [], owner_id: null, stages: ["Review", "Closed", "Rejected"], status: 'Ongoing'};
        this.form.owner_id = this.$store.state.user.id;
      }
      
      

      return this.$refs['projectref'].show()
    },
    
    save() {
      this.$v.$touch()

      if(!this.form.custom_data['industry']) this.form.custom_data['industry'] = [];
      if(!this.form.custom_data['enterprise_function']) this.form.custom_data['enterprise_function'] = [];

      if (this.instance_config.app_config.base.version == 'dev354' && (this.$v.$invalid || (this.form.custom_data['industry'].length + this.form.custom_data['enterprise_function'].length) == 0)) {
        if((this.form.custom_data['industry'].length + this.form.custom_data['enterprise_function'].length) == 0) {
          this.min_domain_error = true;
        }
        this.submitStatus = 'ERROR';
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        Vue.http.post(`${Config.config.base_host}/projects`, this.form).then(res => {
          if(this.call_origin == "ecosystem") {
            this.$refs['projectref'].hide();
          } else {
            location.href="/projects/" + res.body._id + '/dashboard';
          }
        });
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    User,
    TaxonomySelect,
    ImageSelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
