<template lang="pug">
  .filter-group
 
    v-select(:taggable="false", placeholder="Select project" v-model="value", :multiple="multiple", :filterable="false", label="name", :options="options", @search="onSearch")
        template(slot="no-options") Type to search
        template(slot="option" slot-scope="option")

          template(v-if="option.name == 'add'")
            div.p-1.pl-2
              b + Create new project
          template(v-else)
            .small
                  template(v-if="option.web_entity")
                      img(:src="'https://hy.ecosystem-manager.co/images/unsafe/trim/200x200/' + option.web_entity" style="width: 20px; height: 20px").mr-1
                  template(v-else)
                      avatar(:username="option.name", :size="30", :inline="true").mr-1 
                  
                  | {{ option.name.substring(0,20) }}
        template(slot="selected-option" slot-scope="option")
            .selected
                | {{ option.name }}

</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
export default {
  name: 'ProjectSelect',
  props: ["value", "title", "collpased", "multiple"],
  data: function () {
    return {
        uid: null,
        options: []
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

     
  
  methods: {
    
   onSearch(search, loading) {
      
      loading(true);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
      
      Vue.http.get(`${Config.config.base_host}/projects?q=${escape(search)}`).then(res => {
        vm.options = res.body;
        vm.options.unshift({name: "add"})
        loading(false);
      });

    }, 350)
  },

  components: {
    Avatar
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
